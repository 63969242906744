
<template>
<div>
 <b-button  variant="primary"
      class="btn-icon m-2" @click="$refs.myPdfComponent.print()">
  <feather-icon
                icon="PrinterIcon"
                size="22"
              />
              <span class="ml-25 align-middle">print</span>
 </b-button> 
 <pdf ref="myPdfComponent" src="https://www.phfa.org/forms/heelp/heelp_pre_screening.pdf"></pdf>
</div>
</template>

<script>

import pdf from 'vue-pdf'


export default {
  components: {

  pdf
  },
  
};
</script>